<template>

<div class="col-12"> 
  <select v-model="filename" >
     <option  v-for="filename in filenames"  v-bind:key="filename" v-bind:value="filename">{{ filename.replace('.zip','').replace('firmware/','') }}</option>
  </select >
  <br>
  <input type="text" placeholder="AzureIoTHubKey" /> 
    <br>
  <input type="text" placeholder="WiFi Access Point" /> 
    <br>
  <input type="text" placeholder="WiFi Password" />
<br>
  <button v-on:click="setData"> 設定 </button>
  <iframe id="iframe-writer"  style="height: 80vh;"  class="col-12" :src="url">
  </iframe>
  
</div>

</template>

<script>
import { defineComponent } from "vue";
import JSZip from 'jszip';

const getData = async ( obj ) => {

   await new Promise( ( s ) => setTimeout(s, 300));
  return new Promise( (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open('GET', obj.url , true);
        xhr.responseType = "blob";
        xhr.onload = async (e) => {
      // 取得した画像データが e.currentTarget.response にある
        resolve(
            {
                filename : obj.filename,
               path : obj.path  ,
             data :e.currentTarget.response
            }
      );
    };
    xhr.send();
  });
};

export default defineComponent({
   name: "FirmwareWrite",
   components:  { } ,
   data : function() {return { 
     url : "",
     filename : "",
     filenames : [] 
  } }
   , 
  /*, computed: {
  },*/
  async mounted (){
      
var pathname= location.pathname;
    this.url = "/"+pathname.split('/')[1]+'/webfirmware/';
      
    this.$emit('show-header');      

     const  res = await fetch(process.env.VUE_APP_API_URL + "GetCommonFirmListHttpTrigger"+"?dirname=\"\""  + ( process.env.VUE_APP_API_CODE.replace('?','&') ) );
         const json =  await res.json();
        this.filenames = json.filenames;         
            
     
   },
  methods: {
     async setData(){
         var paths = this.filename.split('/');
         const p = paths.pop();
         const dir =paths.join('/');
        var d =await getData(
          { 
          path : dir ,
          filename : p ,
          url: process.env.VUE_APP_API_URL + "/firm/"+ p  + "?directory="+ (dir+ process.env.VUE_APP_API_CODE.replace('?','&') ) 
          }
          
          );
       
       var  datas = {
            "input0" : "e000",
            "input1" : "1000",
            "input2" : "10000",
            "input3" : "8000",
            
            "file0" : undefined,
            "file1" : undefined,
            "file2" : undefined,
            "file3" : undefined
        };
        
        
         JSZip.loadAsync(d.data).then(async (zip)=> {
            
           for (const filename in zip.files) {
                     if ( filename.indexOf('__MACOSX') !== -1){ continue; }
           if(filename.indexOf('.bin') !== -1  ) {  
           const data = await new Promise( (resolve, reject) => {
           
                zip.files[filename].async('blob').then(async (fileData)=> {
                    resolve(fileData) // These are your file contents      
                });     
             });
             
             if(filename.indexOf( "boot_app0") !== -1){
                   datas.file0 = new File([data], filename); 
             }else
             if(filename.indexOf( "bootloader_") !== -1){
                    datas.file1  = new File([data], filename); 
             }else
             if(filename.indexOf( "partitions.bin") !== -1){
                    datas.file3 = new File([data], filename);                
             }else{
                    datas.file2  = new File([data], filename); 
             }
            }  
        }
        document.getElementById('iframe-writer').contentWindow.loadFile(datas);

      
        
        });
     }
       }
});

</script>

<style>

dd{
    display: inline-block;
}

</style>
